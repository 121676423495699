import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { editOffice, getAllOffices, getOneOffice } from './actions';
var initialState = {
    offices: { docs: [], meta: {} },
    office: {},
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'offices',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getAllOffices.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllOffices.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.offices = action.payload;
        })
            .addCase(getAllOffices.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getOneOffice.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneOffice.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.office = action.payload;
        })
            .addCase(getOneOffice.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(editOffice.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(editOffice.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.office = action.payload.data;
        })
            .addCase(editOffice.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
